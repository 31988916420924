import { useEffect, useState } from "react";

const SCROLL_UP = "up";
const SCROLL_DOWN = "down";

/**
 * Detects scroll direction.
 * **borrowed** and modified from https://gist.github.com/reecelucas/cd110ece696cca8468db895281fa28cb
 */
const useScrollDirection = ({
  initialDirection = "up",
  thresholdPixels = 0,
  off = false,
}) => {
  const [direction, setScrollDirection] = useState(initialDirection);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setScrollDirection(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    if (off) {
      setScrollDirection(initialDirection);
    } else {
      window.addEventListener("scroll", onScroll);
    }

    return () => window.removeEventListener("scroll", onScroll);
  }, [initialDirection, off, thresholdPixels]);

  return direction;
};

export default useScrollDirection;
