import React from "react";
import styled from "styled-components";
import TopBar from "lib/components/TopBar";
import Footer from "lib/components/Footer";
import { SpinFixed } from "./Spin";
import Head from "./Head";
import AppBanner from "./AppBanner";
import { UIEvent } from "lib/enums";

interface PageLayoutProps {
  /**
   * Passes the custom component to be displayed on TopBar
   */
  customCompMobile?: React.ReactNode;
  isLoading?: boolean;
  withoutFooter?: boolean;
  withAppBanner?: boolean;
  appBannerEvent?: UIEvent;
}

/**
 * Default page layout.
 */
const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  customCompMobile,
  isLoading,
  withoutFooter,
  withAppBanner,
  appBannerEvent,
}) => {
  return (
    <Wrapper id="page-layout">
      <MetaDefault />
      {withAppBanner && <AppBanner event={appBannerEvent} />}
      <TopBar customCompMobile={customCompMobile} />
      <Main>{children}</Main>
      {!withoutFooter && <Footer />}
      {isLoading && <SpinFixed />}
    </Wrapper>
  );
};

/**
 * Sticky footer (https://developer.mozilla.org/en-US/docs/Web/CSS/Layout_cookbook/Sticky_footers)
 *   - Footer sticks to the bottom of the viewport when content is short.
 *   - If the content of the page extends past the viewport bottom, the footer then sits below the content as normal.
 *   - Using the alternate method here as the grid method interferes with the current layout
 */
const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex-grow: 1;
`;

const MetaDefault = () => {
  const title = "Lawfully: Your definitive US immigration companion";
  const desc =
    "Lawfully strives to be the one-stop solution for all your US immigration needs. Use Lawfully to start tracking your USCIS & NVC cases. Lawfully provides status updates, decision date prediction, approval rates, RFE rates, and ranking of your case. Get your citizenship application reviewed by an immigration attorney. Connect with the US immigration community.";
  const imgUrl = "/share-image-general.jpg";

  return (
    <Head title={title} desc={desc} imageUrl={imgUrl}>
      <meta property="og:locale" content="en_US" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@Lawfully__" />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://www.lawfully.com/",
          potentialAction: {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate:
                "https://www.lawfully.com/community/search/{search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        })}
      </script>
    </Head>
  );
};

export default PageLayout;
