import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import pathOr from "ramda/src/pathOr";
import MuiBox from "@material-ui/core/Box";
import MuiFade from "@material-ui/core/Fade";
import MuiTypography from "@material-ui/core/Typography";
import MuiClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Dialog, useMediaQuery } from "@material-ui/core";
import IconClose from "@material-ui/icons/Close";
import Accordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionSummary, {
  AccordionSummaryProps,
} from "@material-ui/core/AccordionSummary";
import AccordionDetails, {
  AccordionDetailsProps,
} from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "lib/contexts/themeContext";
import { useAuth } from "domain/auth/authContext";
import { useNotification } from "domain/notification/notificationContext";
import Wordmark from "lib/assets/wordmark.svg";
import IconGoogle from "lib/assets/google.svg";
import IconFacebook from "lib/assets/facebook.svg";
import IconEmail from "lib/assets/email.svg";
import IconBell from "lib/assets/bell.svg";
import IconBellDot from "lib/assets/bell-dot.svg";
import IconBack from "lib/assets/back.svg";
import { AsyncStatus, CustomEventLabel, UIEvent } from "lib/enums";
import { useEvent } from "lib/contexts/eventContext";
import useIsResMobile from "lib/hooks/useIsResMobile";
import IconHamburger from "lib/assets/ham.svg";
import Avatar from "lib/components/Avatar";
import { fromNowRecent } from "lib/date";
import { UrlObject } from "url";
import IconChevronRight from "lib/assets/chevron-right.svg";
import { purify } from "lib/format";
import ButtonDownloadApp from "./ButtonDownloadApp";

export const TOPBAR_HEIGHT = 70;

const MENU_ABBREV: MenuConfig = [
  // {
  //   label: "Filing Service (dev)",
  //   href: "/filing-service",
  //   pathRegex: /\/filing-service/,
  //   type: "item",
  // },
  {
    label: "Products",
    type: "group",
    items: [
      {
        label: "USCIS & NVC Case Tracker",
        href: "/case-tracker",
        pathRegex: /\/case-tracker/,
        type: "item",
      },
      {
        label: "Find a Lawyer",
        href: "/lawyers/states/all",
        pathRegex: /\/lawyers\/states/,
        type: "item",
      },
      {
        label: "Mock Interview",
        href: "/mock-interview",
        pathRegex: /\/mock-interview/,
        type: "item",
      },
      {
        label: "Lawfully Pro for businesses",
        href: "/pro",
        pathRegex: /\/pro/,
        type: "item",
      },
    ],
  },
  {
    label: "Community",
    href: "/community",
    pathRegex: /\/community/,
    type: "item",
  },
  {
    label: "Immigration 101",
    href: "/resources",
    pathRegex: /\/resources/,
    type: "item",
  },
  {
    label: "Lawfully Analytics",
    type: "group",
    items: [
      {
        label: "USCIS Case Status Explorer",
        href: "/data/uscis-status-explorer",
        pathRegex: /\/data\/uscis-status-explorer/,
        type: "item",
      },
      {
        label: "USCIS Case Processing Times & Trends",
        href: "/data/trends",
        pathRegex: /\/data\/trends/,
        type: "item",
      },
    ],
  },
];

interface TopBarProps {
  /**
   * Custom component to render on the right side
   * of the TopBar on mobile resolutions
   */
  customCompMobile?: React.ReactNode;
}

const TopBar: React.FC<TopBarProps> = ({ customCompMobile }) => {
  const { breakpoints, zIndex } = useTheme();
  const below960 = breakpoints.down(960);
  const isResMobile = useMediaQuery(below960);

  return (
    <MuiBox
      css={`
        height: ${TOPBAR_HEIGHT}px;
      `}
      component="header"
    >
      <MuiBox
        position={isResMobile ? "relative" : "fixed"}
        zIndex={zIndex.appBar}
        top={0}
        right={0}
        left={0}
        component="nav"
        px={4}
        py={0}
        height={TOPBAR_HEIGHT}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="white"
      >
        <div
          css={`
            display: none;
            ${below960} {
              display: block;
            }
          `}
        >
          <MenuMobile />
        </div>
        <div
          css={`
            width: 100%;
            ${below960} {
              display: none;
            }
          `}
        >
          <MenuDesktop />
        </div>
        {isResMobile && customCompMobile}
      </MuiBox>
    </MuiBox>
  );
};

export default TopBar;

/**
 * Mobile version of the TopBar
 */
const MenuMobile = () => {
  const { query, asPath } = useRouter();
  const { palette } = useTheme();
  const { state, doLogOut } = useAuth();
  const { dataProfile } = state;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnToggleMenu = useCallback(() => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  }, []);

  return (
    <>
      <IconHamburger onClick={handleOnToggleMenu} />

      <Dialog open={isMenuOpen} fullScreen>
        <MuiBox display="flex" flexDirection="column" height="100%">
          {/* top section */}
          <MuiBox
            px={4}
            py={0}
            height={TOPBAR_HEIGHT}
            minHeight={TOPBAR_HEIGHT}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MuiBox
              position="absolute"
              display="flex"
              left="0"
              py="21px"
              px={4}
              onClick={handleOnToggleMenu}
            >
              <IconClose />
            </MuiBox>
            <Link href="/" passHref>
              <a>
                <div
                  css={`
                    width: 90px;
                    height: 27.75px;
                  `}
                >
                  <Wordmark />
                </div>
              </a>
            </Link>
            {state.isLoggedIn && (
              <MuiBox
                position="absolute"
                display="flex"
                right="0"
                py="21px"
                px={4}
              >
                <Notifications onClose={handleOnToggleMenu} />
              </MuiBox>
            )}
          </MuiBox>
          {/* menu section */}
          <MuiBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flexGrow="1"
            py={4}
          >
            <MuiBox display="flex" flexDirection="column" px={0}>
              {MENU_ABBREV.map((menuItem) => {
                if (menuItem.type === "group") {
                  const regexArr = menuItem.items.map((item) => item.pathRegex);

                  // determine if this menu group is active by querying its childrens' regex paths
                  const isActive = regexArr.reduce(
                    (prev, curr) => prev || curr.test(asPath),
                    false,
                  );

                  return (
                    <StyledAccordion key={menuItem.label}>
                      <StyledAccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "black" }} />
                        }
                      >
                        <MenuItemMobile
                          isActive={isActive}
                          css={`
                            padding: 0;
                            font-weight: 500;
                            color: black;
                          `}
                        >
                          {menuItem.label}
                        </MenuItemMobile>
                      </StyledAccordionSummary>
                      <StyledAccordionDetails>
                        {menuItem.items.map((subMenuItem) => {
                          return (
                            <Link
                              key={subMenuItem.label}
                              href={subMenuItem.href}
                              passHref
                            >
                              <MenuItemMobile
                                css={`
                                  background-color: #f9f9fb;
                                `}
                                isActive={subMenuItem.pathRegex.test(asPath)}
                                onClick={handleOnToggleMenu}
                              >
                                {subMenuItem.label}
                              </MenuItemMobile>
                            </Link>
                          );
                        })}
                      </StyledAccordionDetails>
                    </StyledAccordion>
                  );
                }
                return (
                  <Link
                    href={(menuItem as MenuItemConfig).href}
                    key={menuItem.label}
                  >
                    <MenuItemMobile
                      isActive={menuItem.pathRegex.test(asPath)}
                      onClick={handleOnToggleMenu}
                    >
                      {menuItem.label}
                    </MenuItemMobile>
                  </Link>
                );
              })}
              <ButtonDownloadApp
                css={`
                  margin: 20px 0 40px 20px;
                `}
                event={UIEvent.NavMobileAppDownload}
              />
            </MuiBox>
            {state.isLoggedIn ? (
              <>
                {/* logged in menu */}
                <MuiBox
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                  px={5}
                  pt="25px"
                  bgcolor="#F9F9FB"
                  borderTop={`1px solid ${palette.grey[100]}`}
                >
                  <AccountContainer
                    css={`
                      margin-bottom: 15px;
                    `}
                  >
                    {dataProfile ? (
                      <MuiBox display="flex" alignItems="center">
                        <Avatar
                          css={`
                            margin-right: 10px;
                            width: 30px;
                            height: 30px;
                          `}
                          src={dataProfile?.profileImage}
                          letter={dataProfile?.firstName[0]}
                        />
                        <MuiBox
                          fontSize="16px"
                          lineHeight="24px"
                          fontWeight="500"
                        >
                          {dataProfile?.firstName} {dataProfile?.lastName}
                        </MuiBox>
                      </MuiBox>
                    ) : (
                      <button
                        className="Account-button"
                        css={`
                          height: 60px;
                        `}
                      >
                        <div className="Account-button-icon">
                          <ProviderIcon
                            provider={pathOr(
                              "email",
                              ["provider"],
                              state.dataUser,
                            )}
                          />
                        </div>
                        <MuiTypography variant="body1">
                          {state.dataUser?.email}
                        </MuiTypography>
                      </button>
                    )}
                  </AccountContainer>
                  {/* <Link href={{ pathname: "/filing-service/applications" }}>
                    <MenuItemMobileLight>
                      Application Dashboard
                    </MenuItemMobileLight>
                  </Link> */}
                  <Link href={{ pathname: "/consultations" }}>
                    <MenuItemMobileLight>Appointments</MenuItemMobileLight>
                  </Link>
                  <Link href={{ pathname: "/community/activity" }}>
                    <MenuItemMobileLight>
                      Community Activities
                    </MenuItemMobileLight>
                  </Link>
                  <Link href={{ pathname: "/profile" }}>
                    <MenuItemMobileLight>Profile Settings</MenuItemMobileLight>
                  </Link>
                  <Link href={{ pathname: "/change-password" }}>
                    <MenuItemMobileLight>Change Password</MenuItemMobileLight>
                  </Link>
                  <MenuItemMobileLight onClick={doLogOut}>
                    Logout
                  </MenuItemMobileLight>
                </MuiBox>
              </>
            ) : (
              <>
                {/* logged out menu */}
                <MuiBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Link href={{ pathname: "/login", query }}>
                    <MenuItemMobileLight
                      css={`
                        padding: 0 30px;
                        color: black;
                      `}
                    >
                      Login
                    </MenuItemMobileLight>
                  </Link>
                </MuiBox>
              </>
            )}
          </MuiBox>
        </MuiBox>
      </Dialog>
    </>
  );
};

type MenuConfig = Array<MenuItemConfig | MenuGroupConfig>;

/**
 * Represents a group of navigational menu items
 */
interface MenuGroupConfig {
  label: string;
  items: Array<MenuItemConfig>;
  type: "group";
}

/**
 * Represents a single navigational menu item
 */
interface MenuItemConfig {
  href: string | UrlObject;
  label: string;
  pathRegex: RegExp;
  type: "item";
}

const MenuGroupContainer = styled.div`
  position: absolute;
  top: 50px;
  min-width: 280px;
  background-color: white;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: 0px 0px 10px rgba(134, 140, 176, 0.2);
  padding: 20px;
`;

/**
 * Desktop version of the TopBar
 */
const MenuDesktop = () => {
  const { state } = useAuth();
  const {
    dataProfile,
    status: { dataProfile: statusDataProfile },
  } = state;
  const { pathname, query, asPath } = useRouter();

  const [isMenuOpen, setIsMenuOpen] = useState<{
    [menuItemKey: string]: boolean;
  }>({});

  const makeHandleOnOpenMenu = useCallback(
    (key: string) => () => {
      setIsMenuOpen({
        [key]: true,
      });
    },
    [],
  );

  const handleOnCloseMenu = useCallback(
    (key: string) => () => {
      setIsMenuOpen((config) => ({ ...config, [key]: false }));
    },
    [],
  );

  return (
    <MuiBox display="flex" flex={1} alignItems="center">
      <Link href="/" passHref>
        <a
          css={`
            width: 104px;
            height: 40px;
          `}
        >
          <Wordmark />
        </a>
      </Link>

      <MuiBox display="flex" flex={1}>
        <div
          css={`
            flex: 1;
            margin-left: 60px;
            display: flex;
            align-items: center;
          `}
        >
          {MENU_ABBREV.map((menuItem) => {
            if (menuItem.type === "group") {
              const regexArr = menuItem.items.map((item) => item.pathRegex);

              // determine if this menu group is active by querying its childrens' regex paths
              const isActive = regexArr.reduce(
                (prev, curr) => prev || curr.test(asPath),
                false,
              );

              // menu group
              return (
                <MuiClickAwayListener
                  mouseEvent={isMenuOpen[menuItem.label] ? "onClick" : false}
                  onClickAway={handleOnCloseMenu(menuItem.label)}
                  key={menuItem.label}
                >
                  <div
                    css={`
                      position: relative;
                    `}
                  >
                    {/* Button */}
                    <MenuItem
                      href={""}
                      key={menuItem.label}
                      isActive={isActive}
                    >
                      <MuiBox
                        display="flex"
                        alignItems="center"
                        onClick={makeHandleOnOpenMenu(menuItem.label)}
                      >
                        <div
                          css={`
                            font-weight: 500;
                          `}
                        >
                          {menuItem.label}
                        </div>
                        <IconChevronRight
                          css={`
                            margin: 0 4px;
                            transform: rotate(90deg);
                          `}
                          strokeWidth="2"
                          stroke="black"
                        />
                      </MuiBox>
                    </MenuItem>

                    {/* Menu */}
                    <MuiFade in={isMenuOpen[menuItem.label]}>
                      <MuiBox>
                        <MenuGroupContainer>
                          {menuItem.items.map((childMenuItem) => (
                            <MenuItem
                              href={childMenuItem.href}
                              key={childMenuItem.label}
                              isActive={childMenuItem.pathRegex.test(asPath)}
                            >
                              <MuiBox display="flex" alignItems="center">
                                {childMenuItem.label}
                              </MuiBox>
                            </MenuItem>
                          ))}
                        </MenuGroupContainer>
                      </MuiBox>
                    </MuiFade>
                  </div>
                </MuiClickAwayListener>
              );
            }
            return (
              <MenuItem
                href={menuItem.href}
                key={menuItem.label}
                isActive={menuItem.pathRegex.test(asPath)}
              >
                <MuiBox display="flex" alignItems="center">
                  {menuItem.label}
                </MuiBox>
              </MenuItem>
            );
          })}
        </div>

        {state.hasValidatedSavedToken && (
          <MuiBox display="flex" alignItems="center">
            {state.isLoggedIn &&
              (statusDataProfile === AsyncStatus.Success ||
                statusDataProfile === AsyncStatus.Error) && (
                <>
                  <MuiBox
                    mr={4}
                    width="40px"
                    height="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    css={`
                      &:hover {
                        background-color: #f3f4f8;
                        transition: background-color 0.2s linear;
                        border-radius: 50%;
                        cursor: pointer;
                      }
                    `}
                  >
                    <Account user={state.dataUser} profile={dataProfile} />
                  </MuiBox>
                  <MuiBox
                    mr={3}
                    width="40px"
                    height="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    css={`
                      &:hover {
                        background-color: #f3f4f8;
                        transition: background-color 0.2s linear;
                        border-radius: 50%;
                        cursor: pointer;
                      }
                    `}
                  >
                    <Notifications />
                  </MuiBox>
                </>
              )}
            {/* Only render LOGIN button on views other than the LOGIN VIEW itself */}
            {!state.isLoggedIn && !pathname.includes("login") && (
              <MuiBox letterSpacing="1px">
                <MenuItem
                  href={{
                    pathname: "/login",
                    query: { redirect: query.redirect || asPath },
                  }}
                  css={`
                    margin-right: 0;
                  `}
                >
                  <MuiTypography color="textPrimary" variant="body1">
                    Login
                  </MuiTypography>
                </MenuItem>
              </MuiBox>
            )}
          </MuiBox>
        )}
      </MuiBox>
    </MuiBox>
  );
};

interface AccountProps {
  user: ResUser | null;
  profile: ResProfile | null;
}

/**
 * Displays account and settings menu.
 */
const Account: React.FC<AccountProps> = ({ user, profile }) => {
  const { query } = useRouter();
  // State. Is menu visible?
  const [open, setOpen] = useState(false);
  /**
   * Handles email click.
   */
  const handleOnEmailClick = useCallback(() => {
    setOpen((val) => !val);
  }, [setOpen]);
  /**
   * Handles close.
   */
  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!user) {
    return (
      <MuiBox letterSpacing="1px">
        <Link href={{ pathname: "/login", query }}>
          <MuiTypography color="textPrimary" variant="body1">
            Login
          </MuiTypography>
        </Link>
      </MuiBox>
    );
  }

  return (
    <MuiClickAwayListener
      onClickAway={handleOnClose}
      mouseEvent={open ? "onClick" : false}
    >
      <AccountContainer>
        {/* Button */}
        <button className="Account-button" onClick={handleOnEmailClick}>
          {/* Display profile, if possible. */}
          {profile ? (
            <MuiBox display="flex" alignItems="center">
              <Avatar
                css={`
                  width: 30px;
                  height: 30px;
                `}
                src={profile.profileImage}
                letter={profile.firstName[0]}
              />
            </MuiBox>
          ) : (
            <>
              <div className="Account-button-icon">
                <ProviderIcon provider={pathOr("email", ["provider"], user)} />
              </div>
            </>
          )}
        </button>

        {/* Menu */}
        <MuiFade in={open}>
          <MuiBox>
            <AccountMenu onClose={handleOnClose} profile={profile} />
          </MuiBox>
        </MuiFade>
      </AccountContainer>
    </MuiClickAwayListener>
  );
};

const AccountContainer = styled.div`
  position: relative;
  z-index: 10;
  .Account-button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
  }
  .Account-button-icon {
    display: flex;
  }
`;

const NotificationsContainer = styled.div`
  position: relative;
  z-index: 11;
  .Notifications-button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
  }
  .Notifications-button-icon {
    display: flex;
  }
`;

interface AccountMenuProps {
  onClose: () => void;
  profile: ResProfile | null;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ onClose, profile }) => {
  const {
    state: { dataUser },
    doLogOut,
  } = useAuth();
  const { tag } = useEvent();
  const { push } = useRouter();

  const handleOnChangePassword = useCallback(() => {
    push("/change-password");
    onClose();
  }, [push, onClose]);

  const handleOnLogOut = useCallback(() => {
    tag?.(CustomEventLabel.TopNavLogOut);
    doLogOut();
  }, [tag, doLogOut]);

  const handleOnApplications = useCallback(() => {
    push("/filing-service/applications");
    onClose();
  }, [push, onClose]);

  const handleOnMyAppointment = useCallback(() => {
    push("/consultations");
    onClose();
  }, [push, onClose]);

  const handleOnCommunityActivity = useCallback(() => {
    push("/community/activity");
    onClose();
  }, [push, onClose]);

  const handleOnProfileSettings = useCallback(() => {
    push("/profile");
    onClose();
  }, [push, onClose]);

  return (
    <AccountMenuContainer>
      {(profile || dataUser?.email) && (
        <div
          css={`
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding: 10px 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
        >
          {profile
            ? `${profile.firstName} ${profile.lastName}`
            : dataUser?.email}
        </div>
      )}
      {/* <button className="AccountMenu-item" onClick={handleOnApplications}>
        Application Dashboard
      </button> */}
      <button className="AccountMenu-item" onClick={handleOnMyAppointment}>
        Appointments
      </button>
      <button className="AccountMenu-item" onClick={handleOnCommunityActivity}>
        Community Activities
      </button>
      <button className="AccountMenu-item" onClick={handleOnProfileSettings}>
        Profile Settings
      </button>
      {dataUser && !("provider" in dataUser && dataUser.provider) && (
        <button className="AccountMenu-item" onClick={handleOnChangePassword}>
          Change Password
        </button>
      )}
      <button className="AccountMenu-item" onClick={handleOnLogOut}>
        Log out
      </button>
    </AccountMenuContainer>
  );
};

const AccountMenuContainer = styled.div`
  position: absolute;
  top: 50px;
  right: -20px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: 0px 0px 10px rgba(134, 140, 176, 0.2);
  padding: 10px 0;
  width: 260px;
  background-color: ${({ theme }) => theme.palette.common.white};
  .AccountMenu-item {
    outline: none;
    border: none;
    padding: 18px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 16px;
    background-color: ${({ theme }) => theme.palette.common.white};
    cursor: pointer;
    ${({ theme }) =>
      `transition: background-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut};`}
    :focus, :hover {
      background-color: #f3f4f8;
    }
  }
`;

interface ProviderIconProps {
  provider: "google" | "facebook" | "email";
}

const ProviderIcon: React.FC<ProviderIconProps> = ({ provider }) => {
  const theme = useTheme();

  return provider === "google" ? (
    <IconGoogle />
  ) : provider === "facebook" ? (
    <IconFacebook />
  ) : (
    <IconEmail fill={theme.palette.common.black} />
  );
};

const MenuItemMobile = styled.button<{ isActive?: boolean }>`
  text-align: left;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 20px;
  height: 60px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0 30px;
  width: 100%;
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  color: #555;
`;

const MenuItemMobileLight = styled(MenuItemMobile)`
  padding: 0;
  font-weight: normal;
`;

/**
 * Displays a button and a list of notifications.
 */
const Notifications = (props: { onClose?: () => void }) => {
  const {
    state: { dataListNotification },
    doGetListNotification,
    doReadListNotification,
  } = useNotification();
  // Is menu open?
  const [isOpen, setIsOpen] = useState(false);
  /**
   * Handles menu open.
   */
  const handleOnOpen = useCallback(() => {
    setIsOpen((val) => !val);
    // Mark all notifications as read.
    doReadListNotification();
  }, [doReadListNotification]);
  /**
   * Handles menu close.
   */
  const handleOnClose = useCallback(() => {
    setIsOpen(false);
    props.onClose?.();
  }, [props]);

  // Retrieve notifications.
  useEffect(() => {
    doGetListNotification();
  }, [isOpen, doGetListNotification]);

  return (
    <MuiClickAwayListener
      onClickAway={handleOnClose}
      mouseEvent={isOpen ? "onClick" : false}
      touchEvent={isOpen ? "onTouchEnd" : false}
    >
      <NotificationsContainer>
        {/* Button */}
        <button className="Notifications-button" onClick={handleOnOpen}>
          <div className="Notifications-button-icon">
            {dataListNotification && dataListNotification.unreadCount > 0 ? (
              <IconBellDot />
            ) : (
              <IconBell />
            )}
          </div>
        </button>
        {/* Notification items */}
        <MuiFade in={isOpen}>
          <MuiBox>
            <NotificationContents onClose={handleOnClose} />
          </MuiBox>
        </MuiFade>
      </NotificationsContainer>
    </MuiClickAwayListener>
  );
};

interface NotificationContentsProps {
  onClose: () => void;
}

const NotificationContents: React.FC<NotificationContentsProps> = ({
  onClose,
}) => {
  const isResMobile = useIsResMobile();
  const router = useRouter();
  const {
    state: { dataListNotification },
  } = useNotification();
  /**
   * Handles notification item click.
   */
  const handleOnItemClick = useCallback(
    async (navigation: ResNotification["navigation"]) => {
      const match = navigation.match(/community\/posts\/(\d+)/);
      if (!match) return;
      const postId = match[1];
      await router.push(
        { pathname: `/community/posts/[postId]` },
        { pathname: `/community/posts/${postId}` },
      );
      onClose();
    },
    [onClose, router],
  );

  return (
    <NotificationContentsContainer>
      {isResMobile ? (
        <MuiBox mb={2} position="relative">
          <MuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="60px"
            lineHeight="22px"
            fontSize="16px"
            fontWeight={700}
            textAlign="center"
          >
            Notifications
          </MuiBox>
          <MuiBox position="absolute" left={0} top={0}>
            <IconBack onClick={onClose} />
          </MuiBox>
        </MuiBox>
      ) : (
        <MuiBox
          position="relative"
          px="20px"
          py="10px"
          lineHeight="24px"
          fontSize="16px"
          fontWeight={700}
        >
          Notifications
        </MuiBox>
      )}
      <MuiBox flex={1} overflow="auto">
        {dataListNotification?.items.map((item) => (
          <NotificationItem
            key={item.notificationId}
            contents={item.contents}
            imageUrl={item.imageUrl}
            date={item.createdAt}
            navigation={item.navigation}
            onClick={handleOnItemClick}
          />
        ))}
      </MuiBox>
    </NotificationContentsContainer>
  );
};

const NotificationContentsContainer = styled.div`
  position: absolute;
  top: 50px;
  right: -15px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: 0px 0px 10px rgba(134, 140, 176, 0.2);
  padding: 10px 0;
  width: 360px;
  max-height: 575px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  ${(props) => `${props.theme.breakpoints.down("sm")} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
  `}
`;

const NotificationItem = ({
  contents,
  imageUrl,
  date,
  navigation,
  onClick,
}) => {
  const { transitions } = useTheme();

  const handleOnClick = useCallback(() => {
    onClick(navigation);
  }, [navigation, onClick]);

  return (
    <button
      css={`
        padding: 20px 20px 10px;
        display: flex;
        border: none;
        width: 100%;
        background: none;
        text-align: left;
        cursor: pointer;
        transition: background-color ${transitions.duration.shortest}ms
          ${transitions.easing.easeInOut};
        :hover {
          background-color: #f3f4f8;
        }
      `}
      onClick={handleOnClick}
    >
      <Avatar
        css={`
          margin-right: 10px;
        `}
        src={imageUrl}
      />
      <MuiBox flex={1} overflow="hidden">
        <MuiBox lineHeight="22px" fontSize="14px;">
          <span dangerouslySetInnerHTML={{ __html: purify(contents) }} />
        </MuiBox>
        <MuiBox lineHeight="16px" fontSize="12px" color="#777">
          {fromNowRecent(date)}
        </MuiBox>
      </MuiBox>
    </button>
  );
};

interface MenuItemProps {
  href: string | UrlObject;
  className?: string;
  isActive?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  href,
  children,
  className,
  isActive,
}) => {
  return (
    <>
      {href ? (
        <Link href={href} passHref>
          <MenuAnchor isActive={isActive} className={className}>
            {children}
          </MenuAnchor>
        </Link>
      ) : (
        <MenuAnchor isActive={isActive} className={className}>
          {children}
        </MenuAnchor>
      )}
    </>
  );
};

type MenuAnchorProps = {
  className?: string;
  isActive?: boolean;
  href?: string | UrlObject;
  children: React.ReactNode;
  onClick?: () => void;
};

/**
 * In order for next/Link to work properly, we must wrap the function component with
 * forwardRef
 * https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component
 */
const MenuAnchor = forwardRef<HTMLAnchorElement, MenuAnchorProps>(
  ({ className, isActive, children, href, onClick }, ref) => (
    <a
      ref={ref}
      href={href as string}
      onClick={onClick}
      className={className}
      css={`
        display: inline-block;
        position: relative;
        margin: 10px 20px 10px 0;
        line-height: 20px;
        font-size: 16px;
        letter-spacing: 0.2px;
        color: black;
        ${isActive ? `font-weight: 600;` : `font-weight: 400;`}
        :hover {
          color: black;
        }
      `}
    >
      <div
        css={`
          padding: 8px 10px;
          border-radius: 10px;
          &:hover {
            transition: background-color 0.2s linear;
            background-color: #f3f4f8;
          }
        `}
      >
        {children}
      </div>
    </a>
  ),
);

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion
    {...props}
    classes={{ expanded: "expanded" }}
    TransitionProps={{ unmountOnExit: true }}
  />
))`
  && {
    border: none;
    border-radius: none;
    width: 100%;
    box-shadow: none;
    &.expanded {
      margin: 0 auto;
    }
    &:before {
      display: none;
    }
  }
`;

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    {...props}
    classes={{ content: "content", expandIcon: "expandIcon" }}
  />
))`
  &&& {
    margin-bottom: -1;
    padding: 0 30px;
    min-height: auto;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    .content {
      margin: 0;
    }
  }
`;

const StyledAccordionDetails = styled((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
))`
  && {
    padding: 10px 0;
    display: block;
    line-height: 22px;
    font-size: 14px;
    white-space: pre-wrap;
  }
`;
