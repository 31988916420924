import React from "react";
import HeadNext from "next/head";

interface HeadProps {
  title?: string;
  /**
   * Accepts url location in relative format
   */
  url?: string;
  /**
   * Accepts image location in relative format
   */
  imageUrl?: string;
  desc?: string;
}

/**
 * Use this component safely down in the component tree without worrying
 * about duplicate meta tags
 */
const Head: React.FC<HeadProps> = ({
  title,
  url,
  imageUrl,
  desc,
  children,
}) => {
  const hostUrl = process.env.NEXT_PUBLIC_HOMEPAGE_URL;

  const imageUrlFull = imageUrl?.includes("http")
    ? imageUrl
    : `${hostUrl}${imageUrl}`;
  const urlFull = url?.includes("http") ? url : `${hostUrl}${url}`;

  return (
    <HeadNext>
      {title && (
        <>
          <title>{title}</title>
          <meta name="twitter:title" content={title} />
          {/* we provide key for meta properties so that the Head component can dedupe them
          identical meta names are deduped automatically */}
          <meta property="og:title" content={title} key="og:title" />
        </>
      )}
      {desc && (
        <>
          <meta name="description" content={desc} />
          <meta name="twitter:description" content={desc} />
          <meta property="og:description" content={desc} key="og:description" />
        </>
      )}
      {imageUrl && (
        <>
          <meta name="image" content={imageUrlFull} />
          <meta name="twitter:image" content={imageUrlFull} />
          <meta property="og:image" content={imageUrlFull} key="og:image" />
        </>
      )}
      {url && (
        <>
          <link rel="canonical" href={urlFull} key="link:canonical" />
          <meta name="twitter:url" content={urlFull} />
          <meta property="og:url" content={urlFull} key="og:url" />
        </>
      )}
      {children}
    </HeadNext>
  );
};

export default Head;
