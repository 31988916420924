import { useEvent } from "lib/contexts/eventContext";
import { useCallback, useMemo } from "react";
import { UIEvent } from "lib/enums";
import { getAirbridgeTrackerLink } from "lib/airbridge";

const ButtonDownloadApp: React.FC<{ event: UIEvent; className?: string }> = ({
  event,
  className,
}) => {
  const { track } = useEvent();

  const handleOnClick = useCallback(() => {
    track(event);
  }, [event, track]);

  const { adgroup, creative } = useMemo(() => {
    const config = {
      adgroup: undefined,
      creative: undefined,
    };
    switch (event) {
      case UIEvent.HubMobileTopAppDownloadClicked:
        config.adgroup = "Hub";
        config.creative = "Top App Download CTA";
        break;
      case UIEvent.CaseTrackerMobileTopAppDownloadClicked:
        config.adgroup = "Case Tracker";
        config.creative = "Top App Download CTA";
        break;
      case UIEvent.NavMobileAppDownload:
        config.adgroup = "Nav";
        config.creative = "Menu App Download CTA";
        break;
      default:
    }

    return config;
  }, [event]);

  return (
    <div className={className}>
      <a
        css={`
          display: inline-block;
          border: 1px solid ${({ theme }) => theme.palette.primary.main};
          border-radius: 30px;
          padding: 11px 20px;
          color: ${({ theme }) => theme.palette.primary.main};
          :hover {
            color: ${({ theme }) => theme.palette.primary.main};
          }
        `}
        href={getAirbridgeTrackerLink(true, adgroup, creative)}
        target="_blank"
        rel="noreferrer"
        onClick={handleOnClick}
      >
        Download the app
      </a>
    </div>
  );
};

export default ButtonDownloadApp;
