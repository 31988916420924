const CHANNEL_WEB = "lawfully_web";
const CAMPAIGN_MOBILE = "mobile";
const CAMPAIGN_DESKTOP = "desktop";

export type Adgroup =
  | "Case Tracker"
  | "Mock Interview"
  | "Community"
  | "Nav"
  | "USMEx"
  | "Hub"
  | "Resources"
  | "DS-TrendGraph"
  | "Footer";

export function getAirbridgeTrackerLink(
  isMobile: boolean,
  adgroup?: Adgroup,
  creative?: string,
  fallbackDesktop?: string,
) {
  const append = `campaign=${isMobile ? CAMPAIGN_MOBILE : CAMPAIGN_DESKTOP}${
    adgroup ? `&ad_group=${encodeURIComponent(adgroup)}` : ""
  }${
    adgroup && creative ? `&ad_creative=${encodeURIComponent(creative)}` : ""
  }${
    fallbackDesktop
      ? `&fallback_desktop=${encodeURIComponent(fallbackDesktop)}`
      : ""
  }`;

  return `https://abr.ge/@lawfully/${CHANNEL_WEB}?${append}`;
}
