import { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import IconLogo from "lib/assets/logo.svg";
import IconClose from "lib/assets/close-modal.svg";
import useScrollDirection from "lib/hooks/useScrollDirection";
import { useEvent } from "lib/contexts/eventContext";
import { UIEvent } from "lib/enums";
import { useRouter } from "next/router";
import { Adgroup, getAirbridgeTrackerLink } from "lib/airbridge";

const HEIGHT = 72;
const STORAGE_KEY = "disableAppBannerTop";

interface AppBannerProps {
  event?: UIEvent;
}

const AppBanner: React.FC<AppBannerProps> = ({ event }) => {
  const { track } = useEvent();
  const { pathname } = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const dir = useScrollDirection({
    initialDirection: "up",
    thresholdPixels: HEIGHT,
    off: !isMobile,
  });

  const handleOnClick = useCallback(() => {
    if (event) {
      track(event);
    }
    setIsVisible(false);
    sessionStorage.setItem(STORAGE_KEY, "true");
  }, [event, track]);

  const handleOnClose = useCallback(() => {
    setIsVisible(false);
    sessionStorage.setItem(STORAGE_KEY, "true");
  }, []);

  useEffect(() => {
    if (isMobile) {
      const hide = sessionStorage.getItem(STORAGE_KEY);
      if (!hide) {
        setIsVisible(true);
      }
    }
  }, []);

  const adjustLink = useMemo(() => {
    let adgroup: Adgroup = "Hub";
    if (pathname.indexOf("/mock-interview") > -1) {
      adgroup = "Mock Interview";
    } else if (pathname.indexOf("/case-tracker") > -1) {
      adgroup = "Case Tracker";
    } else if (pathname.indexOf("/community") > -1) {
      adgroup = "Community";
    } else if (pathname.indexOf("/uscis-status-explorer") > -1) {
      adgroup = "USMEx";
    } else if (pathname.indexOf("/data/trends") > -1) {
      adgroup = "DS-TrendGraph";
    }
    return getAirbridgeTrackerLink(true, adgroup, "Top Banner");
  }, [pathname]);

  if (!isMobile || !isVisible) return null;

  return (
    <div
      css={`
        height: ${HEIGHT}px;
      `}
    >
      <div
        css={`
          position: fixed;
          z-index: 1200;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 30px 15px 20px;
          background-color: #f8f8f8;
          transition: all 0.25s ease-in-out;
          transform: translateY(${isVisible && dir === "up" ? 0 : -HEIGHT}px);
        `}
      >
        <div
          css={`
            flex: 1;
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={`
              margin-right: 10px;
              border: 1px solid #eee;
              border-radius: 10px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: white;
            `}
          >
            <IconLogo />
          </div>
          <div
            css={`
              flex: 1;
              line-height: 20px;
              font-size: 14px;
              font-weight: 500;
            `}
          >
            <div>Get FREE real-time</div>
            <div>updates on your phone</div>
          </div>
        </div>
        <div>
          <a
            css={`
              display: flex;
              border: 1px solid ${({ theme }) => theme.palette.primary.main};
              border-radius: 10px;
              padding: 9px 12px;
              line-height: 22px;
              font-size: 14px;
              font-weight: bold;
              color: ${({ theme }) => theme.palette.primary.main};
            `}
            href={adjustLink}
            target="_blank"
            rel="noreferrer"
            onClick={handleOnClick}
          >
            Install
          </a>
        </div>
        <button
          css={`
            position: absolute;
            z-index: 1200;
            top: 0;
            right: 0;
            margin: 0;
            padding: 8px;
            border: none;
            background: none;
          `}
          onClick={handleOnClose}
        >
          <IconClose
            width={16}
            height={16}
            viewBox="0 0 24 24"
            css={`
              width: 16px;
              height: 16px;
              path {
                fill: #999;
              }
            `}
          />
        </button>
      </div>
    </div>
  );
};

export default AppBanner;
